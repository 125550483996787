import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const SAVE_PAYPAL_DATA_REQUEST = 'app/StripePayoutPage/SAVE_PAYPAL_DATA_REQUEST';
export const SAVE_PAYPAL_DATA_SUCCESS = 'app/StripePayoutPage/SAVE_PAYPAL_DATA_SUCCESS';
export const SAVE_PAYPAL_DATA_ERROR = 'app/StripePayoutPage/SAVE_PAYPAL_DATA_ERROR';

export const DISCONNECT_PAYPAL_REQUEST = 'app/StripePayoutPage/DISCONNECT_PAYPAL_REQUEST';
export const DISCONNECT_PAYPAL_SUCCESS = 'app/StripePayoutPage/DISCONNECT_PAYPAL_SUCCESS';
export const DISCONNECT_PAYPAL_ERROR = 'app/StripePayoutPage/DISCONNECT_PAYPAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  paypalDataSaveInProgress: false,
  paypalDataSaved: false,
  disconnectPaypalInProgress: false,
  disconnectPaypalSuccess: false,
  disconnectPaypalError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
      
    case SAVE_PAYPAL_DATA_REQUEST:
      return { ...state, paypalDataSaveInProgress: true };
    case SAVE_PAYPAL_DATA_ERROR:
      return { ...state, paypalDataSaveInProgress: false };
    case SAVE_PAYPAL_DATA_SUCCESS:
      return { ...state, paypalDataSaveInProgress: false, paypalDataSaved: true };

    case DISCONNECT_PAYPAL_REQUEST:
      return { ...state, disconnectPaypalInProgress: true };
    case DISCONNECT_PAYPAL_SUCCESS:
      return { ...state, disconnectPaypalInProgress: false, disconnectPaypalSuccess: true };
    case DISCONNECT_PAYPAL_ERROR:
      return { ...state, disconnectPaypalInProgress: false, disconnectPaypalError: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const savePaypalDataRequest = () => ({
  type: SAVE_PAYPAL_DATA_REQUEST,
});
export const savePaypalDataError = () => ({
  type: SAVE_PAYPAL_DATA_ERROR,
});
export const savePaypalDataSuccess = () => ({
  type: SAVE_PAYPAL_DATA_SUCCESS,
});

export const disconnectPaypalRequest = () => ({
  type: DISCONNECT_PAYPAL_REQUEST,
});
export const disconnectPaypalSuccess = () => ({
  type: DISCONNECT_PAYPAL_SUCCESS,
});
export const disconnectPaypalError = () => ({
  type: DISCONNECT_PAYPAL_ERROR,
});

// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const savePaypalData = (values) => (dispatch, getState, sdk) => {
  dispatch(savePaypalDataRequest());
  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  return sdk.currentUser
    .updateProfile({
      publicData: {
        paypal: true,
      },
      protectedData: {
        paypal: values,
      },
    }, queryParams)
    .then(response => {
      dispatch(savePaypalDataSuccess());

      const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));

      return response;
    })
    .catch(() => dispatch(savePaypalDataError()));
}

export const disconnectPaypal = () => (dispatch, getState, sdk) => {
  dispatch(disconnectPaypalRequest());
  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  return sdk.currentUser
    .updateProfile({
      publicData: {
        paypal: false,
      },
      protectedData: {
        paypal: null,
      },
    }, queryParams)
    .then(response => {
      dispatch(disconnectPaypalSuccess());

      const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));

      return response;
    })
    .catch(() => dispatch(disconnectPaypalError()));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response;
  });
};
